import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { ApiResponse, AppSession, Auth, RegistrationRequest } from '@lib/interfaces';
import { Cities } from '@lib/interfaces/city-response.interface';
import { AuthApiResponse } from '@lib/interfaces/common/auth/auth-api-response.interface';
import { EmailCheck } from '@lib/interfaces/email-check.interface';
import { ErrorApiResponse } from '@lib/interfaces/error-api-response.interface';
import { CreateNewPasswordRequest } from '@lib/interfaces/forgot-password/create-new-password-request.interface';
import { ForgotPasswordToken } from '@lib/interfaces/forgot-password/forget-password-token.interface';
import { ForgotPasswordRequest } from '@lib/interfaces/forgot-password/forgot-password-request.interface';
import { OtpForgotPasswordRequest } from '@lib/interfaces/forgot-password/verify-forgot-password-otp.interface';
import { LoginRequest } from '@lib/interfaces/login-request.interface';
import { OtpRequest } from '@lib/interfaces/otp/otp-request.interface';
import { Provinces } from '@lib/interfaces/province-response.interface';
import { RegistrationResponse } from '@lib/interfaces/registration-response.interface';
import { storage } from '@lib/utils/storage/storage.utils';
import { BehaviorSubject, catchError, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    isAuthenticated$ = new BehaviorSubject<boolean>(!!storage.getItem('appSession'));
    currentSession$ = new BehaviorSubject<AppSession | null>(this._storedSession);

    private readonly _http = inject(HttpClient);
    private readonly _apiUrl = environment.apiUrl;

    private readonly _destroy$ = new Subject();

    private get _storedSession(): AppSession | null {
        return storage.getItem('appSession');
    }

    private set _storedSession(session: AppSession | null) {
        storage.setItem('appSession', session as AppSession);
    }

    public get currentSession(): AppSession | null {
        return this.currentSession$.getValue();
    }

    public get isAuthenticated(): boolean {
        return this.isAuthenticated$.getValue();
    }

    login(data: LoginRequest): Observable<ApiResponse<Auth>> {
        return this._http.post<ApiResponse<Auth>>(this._apiUrl + '/auth/login', data).pipe(
            catchError((error: Error) => {
                return Promise.reject(error);
            }),
        );
    }

    emailCheck(email: string): Observable<EmailCheck> {
        const encodedEmail = encodeURIComponent(email);
        return this._http.get<EmailCheck>(this._apiUrl + '/users/' + encodedEmail + '/checkEmail');
    }

    loginWithGoogle(authToken: string): Observable<ApiResponse<Auth>> {
        const request = {
            token: authToken,
        };
        return this._http.post<ApiResponse<Auth>>(this._apiUrl + '/auth/google/login/patient', request);
    }

    register(data: RegistrationRequest): Observable<ApiResponse<unknown>> {
        return this._http.post<ApiResponse<unknown>>(this._apiUrl + '/auth/register/patient', data);
    }

    registerWithGoogle(authToken: string): Observable<ApiResponse<Auth>> {
        const request = {
            token: authToken,
        };
        return this._http.post<ApiResponse<Auth>>(this._apiUrl + '/auth/google/register/patient', request);
    }

    resendOtp(email: string): Observable<ApiResponse<RegistrationResponse>> {
        return this._http.post<ApiResponse<RegistrationResponse>>(this._apiUrl + '/auth/resend-otp', { email }).pipe(
            catchError((error: Error) => {
                return Promise.reject(error);
            }),
        );
    }

    otpVerification(pin: OtpRequest, token: string): Observable<AuthApiResponse> {
        const headers = new HttpHeaders({
            authorization: `Bearer ${token}`,
        });
        return this._http.post<AuthApiResponse>(this._apiUrl + '/auth/verified', pin, { headers }).pipe(
            catchError((error: Error) => {
                return Promise.reject(error);
            }),
        );
    }

    forgotPassword(req: ForgotPasswordRequest): Observable<ApiResponse<AuthApiResponse>> {
        return this._http.post<ApiResponse<AuthApiResponse>>(this._apiUrl + '/auth/password/forget', req).pipe(
            catchError((error: Error) => {
                return Promise.reject(error);
            }),
        );
    }

    verifyForgotPasswordOtp(req: OtpForgotPasswordRequest): Observable<ApiResponse<ForgotPasswordToken>> {
        return this._http
            .post<ApiResponse<ForgotPasswordToken>>(this._apiUrl + '/auth/password/verification', req)
            .pipe(
                catchError((error: Error) => {
                    return Promise.reject(error);
                }),
            );
    }

    createNewPassword(req: CreateNewPasswordRequest, token: string): Observable<AuthApiResponse> {
        const headers = new HttpHeaders({
            authorization: `Bearer ${token}`,
        });
        return this._http.patch<AuthApiResponse>(this._apiUrl + '/auth/password/reset', req, { headers }).pipe(
            catchError((error: Error) => {
                return Promise.reject(error);
            }),
        );
    }

    loadProvinces(): Observable<ApiResponse<Provinces[]>> {
        const response = this._http.get<ApiResponse<Provinces[]>>(this._apiUrl + `/province`);
        return response;
    }

    loadCities(data: number): Observable<ApiResponse<Cities[]>> {
        const response = this._http.get<ApiResponse<Cities[]>>(this._apiUrl + `/province/${data}/city`);
        return response;
    }

    errorHttpCatch(error: HttpErrorResponse): Observable<ErrorApiResponse> {
        const errorResponse: ErrorApiResponse = error.error as ErrorApiResponse;
        return new Observable((observer) => {
            errorResponse;
            observer.next(errorResponse);
            observer.complete();
        });
    }

    logout(): void {
        storage.removeItem('appSession');
        this.isAuthenticated$.next(false);
        location.reload();
    }

    ngOnDestroy(): void {
        this._destroy$.complete();
        this._destroy$.unsubscribe();
    }

    init(): void {
        this.setSession(this._storedSession);
    }

    /**
     * Manually changes session in LocalStorage & HTML body
     *
     * @param session new session
     */
    setSession(session: AppSession | null): void {
        this._storedSession = session;
        this.currentSession$.next(session);
    }
}
